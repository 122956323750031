<template lang='pug'>
  div
    input(placeholder='Поиск по таблице' style='width: 450px' v-model='searchContainer.predictive' @keyup.enter='confirmSearch()')
    button.ml-1.bg-green-500.text-white(@click='confirmSearch()') Найти

    .container-table.rounded-lg.mt-2
      .headers-line.flex
        template(v-for='(value, key) in headers')
          .column(:style='`width: ${(90 / Object.keys(headers).length)}%`')
            p {{ value }}
        .column(style='width: 10%')
          .text-center Действия
      
      .body-line
        template(v-for='(value, index) in paginated')
          .flex.mt-2
            template(v-for='(name, key) in headers')
              .column(:style='`width: ${(90 / Object.keys(headers).length)}%`')
                p(:class='{ "opacity-30": !value[key] }') {{ typeof value[key] == 'boolean' ? (value[key] ? 'Да' : 'Нет') : (!value[key] ? 'Нет данных' : value[key]) }}
            
            .column.flex.gap-3.justify-center.w-full(style='width: 10%')
              template(v-if='canAny')
                template(v-if='canView')
                  p(@click='actions.view(value)') V
                template(v-if='canEdit')
                  p(@click='actions.edit(value)') E
                template(v-if='canRemove')
                  p.text-red-500(@click='actions.remove(value)') X
              template(v-else)
                .text-sm.opacity-30 Нет действий
      
      .pagination-line
        .flex
          button.mr-1.bg-gray-300(@click='paginationContainer.page = Math.max(0, paginationContainer.page - 1)') Назад
          input.text-center(v-model.number='paginationContainer.page' style='width: 50px;')
          button.ml-1.bg-gray-300(@click='paginationContainer.page = Math.min(pages, paginationContainer.page + 1)') Вперёд
</template>

<script>
const PAGINATION_PER_PAGE = 10;

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    search: {
      type: Boolean,
      required: false
    },
    headers: {
      type: Object,
      required: true
    },
    actions: {
      type: Object,
      required: false,
      default: () => ({
        edit: () => {},
        remove: null,
        view: () => {}
      })
    }
  },
  computed: {
    canAny() {
      return this.canEdit || this.canRemove || this.canView;
    },
    canView() {
      return this.actions.view != null
    },
    canEdit() {
      return this.actions.edit != null
    },
    canRemove() {
      return this.actions.remove != null
    },
    filtered() {
      if (!this.searchContainer.current) {
        return this.data;
      }

      const list = this.data.filter(v => {
        const fields = Object.keys(v);

        return fields.some(f => v[f].toString().toLowerCase().includes(this.searchContainer.current.toLowerCase()));
      });

      return list;
    },
    paginated() {
      const data = this.filtered;

      return data.slice(this.paginationContainer.page * PAGINATION_PER_PAGE, (this.paginationContainer.page + 1) * PAGINATION_PER_PAGE);
    },
    pages() {
      return Math.ceil(this.filtered.length / PAGINATION_PER_PAGE);
    }
  },
  data() {
    return {
      searchContainer: {
        predictive: null,
        current: null
      },
      paginationContainer: {
        page: 0
      }
    }
  },
  methods: {
    /**
     * Осуществить поиск по таблице
     */
    confirmSearch() {
      this.searchContainer.current = this.searchContainer.predictive;

      this.paginationContainer.page = 0;
    }
  }
}
</script>

<style lang='scss'>
  .container-table {
    overflow: hidden;
  }
  .headers-line {
    background: whitesmoke;
    padding: 5px 10px;
  }
  .body-line {
    padding: 5px 10px;
  }
</style>